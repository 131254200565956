import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class CategoryService {
  private _categoryList = [];

  constructor(private httpService: HttpService) {
  }

  getCategoryList = async (userData) => {
    // if (this._categoryList.length > 0) {
    //   return this._categoryList;
    // }
    // let getCategoryListResponse = await this.httpService.get("distributor/getCategoryList").toPromise();
    // let categories = [];
    // if (getCategoryListResponse) {
    //   let side = userData.side;
    //   getCategoryListResponse.data.category_list.forEach((catItem) => {
    //     if (side == catItem.side) {
    //       let item = {
    //         id: catItem.id,
    //         name: catItem.name,
    //       };
    //       categories.push(item);
    //     }
    //   });
    // }
    // if (categories.length > 0) {
    //   categories = categories.sort((a, b) => a.name.localeCompare(b.name));
    // }
    // this._categoryList = categories;
    return this._categoryList;
  };
}
